import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/form',
    name: 'Form',
    component: () => import(/* webpackChunkName: "form" */ '../views/Form.vue')
  },
  {
    path: '/confirmation',
    name: 'Confirmation',
    component: () => import(/* webpackChunkName: "confirmation" */ '../views/Confirmation.vue')
  },
  {
    path: '/fiat500e-v2',
    name: 'fiat500e V2',
    props: {
      title: 'Fiat 500e 100% électrique',
      offre: '109€ TTC/mois<br>LLD 37 mois sans condition de reprise.<br>Après apport de 2&nbsp;500€, déduction faite du bonus écologique.',
      contrat: 'LLD 37 mois',
      img: 'img/cars/fiat500_2.png'
    },
    component: () => import(/* webpackChunkName: "fiat500e" */ '../views/stellantis/fiat500e-v2.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {x: 0, y:0}
  },
})

export default router
